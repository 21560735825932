import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import { Box, Button, ButtonBase, Typography } from '@material-ui/core';
import { ApplicationContext } from '@product-site-frontend/shared';
import currency from 'currency.js';

import { LeasingCalcContext } from '../../LeasingCalcContext';
import Styled from './LeasingCalculatorTab.styled';

LeasingCalcDataBox.propTypes = {
  fullAmount: PropTypes.number.isRequired,
  monthlyPayment: PropTypes.number.isRequired,
  onActionButtonClick: PropTypes.func,
  onSwitchToTariffs: PropTypes.func,
  savingAmount: PropTypes.number.isRequired,
}

export default function LeasingCalcDataBox({
  fullAmount,
  monthlyPayment,
  onActionButtonClick,
  onSwitchToTariffs,
  savingAmount
}) {
  const { setAmount, setApplicationOpen } = useContext(ApplicationContext);
  const { calcData } = useContext(LeasingCalcContext);

  const handleApplicationClick = useCallback(() => {
    setAmount(calcData.amount);
    setApplicationOpen(true);

    if (onActionButtonClick) {
      onActionButtonClick();
    }
  }, [calcData, setApplicationOpen, setAmount, onActionButtonClick]);

  const formatMoney = useCallback((value) => {
    return currency(value, { precision: 0 }).format({
      separator: ' ',
      symbol: 'руб',
      pattern: '# !',
    });
  }, []);

  return (
    <Box sx={Styled.DataBox}>
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
          Ежемесячный платеж, включая НДС
        </Typography>
        <Typography sx={{ fontSize: 36, fontWeight: 700 }}>
          {formatMoney(monthlyPayment)}
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
          Сумма договора лизинга
        </Typography>
        <Typography sx={{ fontSize: 30, fontWeight: 700 }}>
          {formatMoney(fullAmount)}
        </Typography>
      </Box>
      <Box sx={{ mb: 7 }}>
        <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
          Суммарная экономия по договору
        </Typography>
        <Typography sx={{ fontSize: 30, fontWeight: 700 }}>
          до {formatMoney(savingAmount)}
        </Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Button
          color="primary"
          fullWidth
          onClick={handleApplicationClick}
          size="large"
          sx={Styled.ApplicationButton}
          variant="contained"
        >
          Оформить лизинг
        </Button>
        <Typography sx={{ fontSize: 14, fontWeight: 500, mt: 4 }}>
          Рассчитано по ставке тарифа{' '}
          <Typography
            color="#4a90e2"
            component={ButtonBase}
            disableRipple
            onClick={onSwitchToTariffs}
            sx={{ fontSize: 14, fontWeight: 'inherit', textAlign: 'left' }}
          >
            #1 Масштабирование
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption">
          Расчеты информационные. Точные параметры определяются индивидуально{' '}
          для каждого клиента по результатам рассмотрения заявки. Для получения{' '}
          более выгодных условий подайте заявку, и мы с вами свяжемся.
        </Typography>
      </Box>
    </Box>
  );
}